<template>
  <div class="userInfoDetail">
    <!-- 用户头像 /标签 -->
    <div class="user-avatar">
      <div class="avatar">
        <div class="photo">
          <img src="./img/user.png" alt="" />
        </div>
        <div class="name">{{ detaliParams.realName }}</div>
      </div>
      <div class="tag" v-if="detaliParams.isRelated == 1">家友</div>
    </div>
    <!-- 图片 -->
    <div class="imgs">
      <swiper-preview
        :swiperData="(detaliParams.imageUrl || '').split(',')"
        :autoplay="2000"
      ></swiper-preview>
    </div>
    <div class="describe">
      {{ detaliParams.demandDesc }}
    </div>
    <div class="contact" @click="contact">联系ta</div>
  </div>
</template>

<script>
import swiperPreview from "./components/swiper-preview.vue";
import { getCoffeeDemandById } from "./api.js";
export default {
  name: "userInfoDetail",
  components: { swiperPreview },
  props: {},
  data() {
    return {
      detaliParams: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    if (id != undefined) {
      this.getCoffeeDemandDetailt(id);
    }
  },
  mounted() {},
  methods: {
    contact() {
      window.location.href = `tel://${this.detaliParams.mobile}`;
    },
    getCoffeeDemandDetailt(id) {
      this.$axios.get(`${getCoffeeDemandById}?id=${id}`).then((res) => {
        if (res.code == 200) {
          this.detaliParams = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.userInfoDetail {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 32px 30px 0 30px;
  padding-bottom: calc(150px + constant(safe-area-inset-bottom));
  padding-bottom: calc(150px + env(safe-area-inset-bottom));
  .user-avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 38px;
    box-sizing: border-box;
    .avatar {
      flex: 1;
      max-width: calc(100% - 154px);
      display: flex;
      align-items: center;
      .photo {
        flex-shrink: 0;
        margin-right: 18px;
        width: 102px;
        height: 102px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
      }
    }
    .tag {
      width: 134px;
      height: 50px;
      border-radius: 8px;
      border: 2px solid #1e679e;
      text-align: center;
      line-height: 46px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1e679e;
    }
  }
  .imgs {
    width: 100%;
    height: 690px;
    overflow: hidden;
    border-radius: 12px;
  }
  .describe {
    margin-top: 40px;
    box-sizing: border-box;
    width: 100%;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }
  .contact {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
    width: 530px;
    height: 70px;
    background: #8a95a6;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
  }
}
</style>
